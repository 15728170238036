import React, { Component } from "react";
import CabPage from "../CabPage";
import axios from "axios";
import Button from "react-bootstrap/esm/Button";

function PrintCupom(sCupom, nVias)
{
    let sPrint = "";
    sPrint = sCupom + 
      "<br><br>" + 
      "________________________________________________________<br>" + 
      "<i style='font-size: 11pt'>Assinatura</i><br><br>" + 
      "- - - - - - - - - - - - - - - - - - - - - - - - - -<br><br><br>";
    if (nVias == 2)
    {
      sPrint = sPrint + sCupom + 
        "<br><br>" + 
        "________________________________________________________<br>" + 
        "<i style='font-size: 11pt'>Assinatura</i><br><br>" + 
        "- - - - - - - - - - - - - - - - - - - - - - - - - -<br><br>";
    }
    sPrint = sPrint +  
        "<style type='text/css' media='print'>" + 
        ".dontprint" + 
        "{ display: none; }" + 
        "</style>" + 
        "<div class='dontprint'>" + 
        "<input type='button' value='Fechar' onclick='window.close()' /></div>";

      var printWindow = window.open('','','width=400,height=700');
      printWindow.document.open('text/html');
      printWindow.document.write(sPrint);
      printWindow.document.close();      
      printWindow.focus();
      printWindow.print();
}

class VenderCupom extends Component {

  state = {
    sCupom: ""
  }

  /* requisição com axios para ler lista de vendas */
  async componentDidMount() {
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_API}TrataGetPost.php`,
      params: {
        prog_trata: "./Vendas/VendasCupom.php",
        func_trata: "PrintCupom",
        id_venda: this.props.idVenda
      }
    })
    .then((response) => {
//      console.log(response.data);
      this.setState({ sCupom: response.data.cupom });
    })
    .catch(function (error) {console.log(error); alert(error)})
  }

  render() {
    return (
      <>
      <CabPage titulo="Cupom" paginavoltar="/venlis" />
      <div className="App-body">
        <center>
        <br/><br/>
        <Button as="button" type="submit" id="bt_1via" onClick={() => PrintCupom(this.state.sCupom, 1)}
                    variant="success" size="lg">Imprimir 1 via do cupom {this.props.idVenda}
        </Button>
        <br/><br/><br/><br/><br/><br/>
        <Button as="button" type="submit" id="bt_2vias" onClick={() => PrintCupom(this.state.sCupom, 2)}
                    variant="warning" size="lg">Imprimir 2 vias do cupom {this.props.idVenda}
        </Button>
        </center>
      </div>
      </>
    )
  }
}

export default VenderCupom;