import React, { Component } from "react";
import CabPage from "../CabPage";
import axios from "axios";
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import FloatingLabel from "react-bootstrap/esm/FloatingLabel";
import { FormataValor, FormataData, CurDate, Separador, CodConsum } from "../k_functions.js";
import Alert from 'react-bootstrap/Alert';

class ParcelasDetalhe extends Component {
  state = {
    validated: false,
    aParcela: [],               // contém a parcela a ser quitada
    aProdutos: [],              // produtos da venda associada a parcela  
    nSaldo: 0.00,               // saldo da parcela após quitação
    dQuitacao: CurDate("YMD")   // data da quitação, default hoje
  };

  /* requisição com axios para ler parcela a quitar de uma certa venda */
  async componentDidMount() {
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_API}TrataGetPost.php`,
      params: {
        prog_trata: "./Financas/FinancasDetalhe.php",
        func_trata: "FinancasDetalhe",
        id_parcela: this.props.idParcela,
        id_venda:   this.props.idVenda
      }
    })
    .then((response) => {
      if (response.data.msg !== "ERRO")
      {
//        console.log(response.data);
        this.setState({aParcela: response.data["parcela"]});
        this.setState({aProdutos: response.data["produtos"]});
      }
    })
    .catch(function (error) {console.log(error); alert(error)})
  }

  render() {
    return (
      <>
        {this.props.bLayoutFull && (
          <>
            <CabPage titulo="Consultar Parcela" paginavoltar={this.props.retPage=="lis" ? "/finsel/lis" : "/finsel/qui"} />
          </>
          )
        }
        <div className="App-body">
          {this.state.aParcela.length > 0 && (
            <>
              <Row>
                <Col>
                  <FloatingLabel controlId="floatingClientes" label="Cliente">
                    <Form.Control type="text" value={this.state.aParcela[0].id_cliente + " - " + 
                      this.state.aParcela[0].nome_razao + 
                      (this.state.aParcela[0].id_cliente === CodConsum() ? " (" + this.state.aParcela[0].nome_consum + ") ": "")} disabled/>
                  </FloatingLabel>
                </Col>
              </Row>
              <br/>
              <Row>
                <Col>
                  <FloatingLabel controlId="floatingVenda" label="Venda">
                      <Form.Control type="number" value={this.state.aParcela[0].id_venda} disabled/>
                    </FloatingLabel>
                </Col>
                <Col>
                  <FloatingLabel controlId="floatingDataVenda" label="Data Venda">
                      <Form.Control type="text" value={this.state.aParcela[0].dt_venda} disabled/>
                    </FloatingLabel>
                </Col>
              </Row>
              <br/>
              <Row>
                <Col>
                  <FloatingLabel controlId="floatingVenda" label="# Parcela">
                      <Form.Control type="number" value={this.state.aParcela[0].id_vendas_parc} disabled/>
                    </FloatingLabel>
                </Col>
                <Col>
                  <FloatingLabel controlId="floatingVenda" label="Parcela">
                      <Form.Control type="number" value={this.state.aParcela[0].num_parcela} disabled/>
                    </FloatingLabel>
                </Col>
              </Row>
              <br/>
              <Row>
                <Col>
                  <FloatingLabel controlId="floatingData" label="Data Criação">
                    <Form.Control type="text" 
                      value={this.state.aParcela[0].data_criacao == null ? "-" : FormataData(this.state.aParcela[0].data_criacao)} disabled/>
                  </FloatingLabel>
                </Col>
                <Col>
                  <FloatingLabel controlId="floatingHora" label="Hora Criação">
                    <Form.Control type="text" 
                      value={this.state.aParcela[0].hora_criacao == null ? "-" : this.state.aParcela[0].hora_criacao} disabled/>
                  </FloatingLabel>
                </Col>
              </Row>
              <br/>
              <Row>
                <Col>
                  <FloatingLabel controlId="floatingVenda" label="Parc Origem">
                      <Form.Control type="number" value={this.state.aParcela[0].id_parc_origem * 1} disabled/>
                    </FloatingLabel>
                </Col>
                <Col>
                  <FloatingLabel controlId="floatingVenda" label="Forma">
                      <Form.Control type="text" value={this.state.aParcela[0].desc_forma} disabled/>
                    </FloatingLabel>
                </Col>
              </Row>
              <br/>
              <Row>
                <Col>
                  <FloatingLabel controlId="floatingVenda" label="Vencimento">
                    <Form.Control type="date" value={this.state.aParcela[0].data_vencim} disabled/>
                  </FloatingLabel>
                </Col>
                <Col>
                  <FloatingLabel controlId="floatingVenda" label="Valor Original">
                      <Form.Control type="number" value={FormataValor(this.state.aParcela[0].valor_parcela, "input")} disabled/>
                  </FloatingLabel>
                </Col>
              </Row>
              <br/>
              {this.state.aParcela[0].quitado == 'S' &&
                <>
                  <Separador/>
                  <br/>
                  <Row>
                    <Col>
                      <FloatingLabel controlId="floatingDQuitacao" label="Data Quitação">
                        <Form.Control type="text" name="Quitacao" 
                          value={this.state.aParcela[0].dt_pgto} disabled />
                      </FloatingLabel>
                    </Col>
                    <Col>
                      <FloatingLabel controlId="floatingVenda" label="Valor Pago">
                          <Form.Control type="number" value={(this.state.aParcela[0].quitado == 'S' ? FormataValor(this.state.aParcela[0].valor_quitado, "input") : "Aberto")} disabled />
                      </FloatingLabel>
                    </Col>
                  </Row>
                  <br/>
                  <Row>
                    <Col>
                      <FloatingLabel controlId="floatingQuitadoEm" label="Quitado Em">
                        <Form.Control type="text" name="QuitadoEm" 
                          value={this.state.aParcela[0].dt_quitacao} disabled />
                      </FloatingLabel>
                    </Col>
                    <Col>
                      <FloatingLabel controlId="floatingHoraQuit" label="Quitado às">
                          <Form.Control type="text" value={this.state.aParcela[0].hora_quitacao} disabled />
                      </FloatingLabel>
                    </Col>
                  </Row>
                </>
              }
              {this.state.aParcela[0].quitado == 'N' && this.props.bLayoutFull &&
                <Alert variant="warning">Parcela em aberto.</Alert>
              }
              <Table striped bordered hover size="sm">
                <thead>
                  <tr align='center'>
                    <th width="30%">Produto</th>
                    <th width="10%">Qtd</th>
                    <th width="30%">Und</th>
                    <th width="15%">$ Unit</th>
                    <th width="15%">$ Total</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.aProdutos.map((oProduto, index) => (
                    <tr key={index} align='center'>
                      <td>{oProduto.desc_produto}</td>
                      <td>{oProduto.qtd_vendida}</td>
                      <td>{oProduto.desc_unidade}</td>
                      <td>{FormataValor(oProduto.valor_unit, "")}</td>
                      <td>{FormataValor(oProduto.qtd_vendida*oProduto.valor_unit)}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </>
          )}
        </div>
      </>
    );
  }
}

export default ParcelasDetalhe;