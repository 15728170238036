import React, { Component, useState} from "react";
import CabPage from "../CabPage";
import Table from 'react-bootstrap/Table';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import axios from "axios";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { FaBinoculars } from "react-icons/fa";
import { FormataValor, SelectFormasPag, trataAxios, CurDate, CodConsum } from "../k_functions.js";
import FloatingLabel from "react-bootstrap/esm/FloatingLabel";
import Nav from "react-bootstrap/esm/Nav";
import Alert from 'react-bootstrap/Alert';
import Modal from 'react-bootstrap/Modal';
import ParcelasDetalhe from "./FinancasDetalhe";

// nao entendi pq quando passo parametros para uma função tal como esta, o parametro é recebido como um objeto;
// então, por isso chamei o parametro de obj
function ShowParcela(obj) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button variant="primary" onClick={handleShow}>
        <FaBinoculars />
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Parcela {obj.idParcela}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ParcelasDetalhe idParcela={obj.idParcela} idVenda={obj.idVenda}/>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

class AvisoParcelaQuitada extends Component {
  render() {
    return (
      <>
      <Alert variant="success">Parcela quitada!</Alert>
      <center>
      <Nav.Link href={`/quitarsel`}>
        <Button as="button" id="bt_add" variant="success" size="sm">Quitar outra parcela</Button>
      </Nav.Link>
      </center>
      </>
    )
  }
}

class QuitarListarEProcessar extends Component {
  state = {
    aForma: [], // array com as formas para quitação
    aValor: [], // array com os valores de quitação
    aParcelas: [],     // contém a lista de parcelas filtradas
    dQuitacao: CurDate("YMD"),
    nSumValSelOrig: 0,  // soma do valor original das parcelas selecionadas
    nSumValSelQuit: 0   // soma do valor informado pelo usuario das parcelas selecionadas
  };

  /* requisição com axios para ler parcelas filtrando pelos parametros do usuário */
  async componentDidMount() {
    let aValor = [];
    let aForma = [];
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_API}TrataGetPost.php`,
      params: {
        prog_trata: "./Financas/FinancasListar.php",
        func_trata: "FinancasListar",
        sts_par: "Aberto",
        dat_ini: this.props.dIni,
        dat_fim: this.props.dFim,
        dtiniVd: this.props.dIVd,
        dtfimVd: this.props.dFVd,
        cod_cli: this.props.cCli,
        cod_for: 0, 
      }
    })
    .then((response) => {
      for (let i = 0; i < response.data.length; i++) {
        aValor.push(response.data[i].valor_parcela);
        aForma.push(response.data[i].id_forma.toString());
      }
      this.setState({aParcelas: response.data});
      this.setState({aValor: aValor});
      this.setState({aForma: aForma});
    })
    .catch(function (error) {console.log(error); alert(error)})
//    console.log(this.state.aForma);
  }

  handleChangeForma = (idx) => e => {
//    console.log("change forma");
    const aForma = this.state.aForma;
    aForma[idx] = e.target.value;
    this.setState({ aForma: aForma });
  };

  handleChangeValor = (idx) => e => {
    // primeiro diminui do total quitado o valor atual da parcela
    const nValParcAtu = this.state.aValor[idx];
    const aValor = this.state.aValor;
    aValor[idx] = e.target.value * 1;
    this.setState({ aValor: aValor });
    // agora soma ao total quitado o valor atual da parcela
    this.setState({nSumValSelQuit: this.state.nSumValSelQuit - nValParcAtu + this.state.aValor[idx]});
  };

  handleChangeDatQui = () => e => {
    //    console.log (e);
        let dQuitacao = e.target.value;
        this.setState({ dQuitacao });
  };

  handleCheckboxChange(e) {
    let idx = e.target.id.substr(3,3); 
    let idParc = e.target.name.substr(8,6);
    let eForma = document.getElementById("idForma_" + idParc);
    let eValor = document.getElementById("idValor_" + idParc);
    /*
    console.log(e.target);
    console.log(eForma);
    console.log(eValor);
    console.log(idParc);
    console.log(idx);
    console.log(this.state.aForma);
    console.log(e.target.checked);
    */
    eForma.disabled = ! eForma.disabled;
    eValor.disabled = ! eValor.disabled;

    if (e.target.checked)
    {
      document.getElementById(idParc + "_" + this.state.aForma[idx]).selected = true;
      document.getElementById("idValor_" + idParc).value = this.state.aValor[idx];
      /*
      document.getElementById("floatingSumValSelOrig").value =
        FormataValor(document.getElementById("floatingSumValSelOrig").value * 1 + this.state.aValor[idx] * 1, "input");
      */
      this.setState({nSumValSelOrig: this.state.nSumValSelOrig + this.state.aValor[idx] * 1});
      this.setState({nSumValSelQuit: this.state.nSumValSelQuit + this.state.aValor[idx] * 1});
      /*
      document.getElementById("floatingSumValSelQuit").value =
        FormataValor(document.getElementById("floatingSumValSelQuit").value * 1 + this.state.aValor[idx] * 1, "input");
      */
    }
    else
    {
      this.setState({nSumValSelOrig: this.state.nSumValSelOrig - this.state.aValor[idx] * 1});
      this.setState({nSumValSelQuit: this.state.nSumValSelQuit - document.getElementById("idValor_" + idParc).value * 1});
      /*
      document.getElementById("floatingSumValSelOrig").value =
        FormataValor(document.getElementById("floatingSumValSelOrig").value * 1 - this.state.aValor[idx], "input");
      document.getElementById("floatingSumValSelQuit").value =
        FormataValor(document.getElementById("floatingSumValSelQuit").value * 1 - document.getElementById("idValor_" + idParc).value * 1, "input");
      */
      document.getElementById(idParc + "_0").selected = true;
      document.getElementById("idValor_" + idParc).value = 0;
    }
  }
  
  handleSubmit = (event) => {
    // primeiro valida o formulario
    let isValid = true;

    // percorre todas as parcelas, independente de estarem ou não marcadas
    // para as marcadas, preenche 4 arrays, um com o id das parcelas
    // outro com as formas das parcelas, outro com os valores quitados das parcelas
    // e outro com os valores originais das parcelas  
    var aNodeListParcelas = document.querySelectorAll('input[name^="ID_PARC_"]');
    var aIDParcs = [];  // id das parcelas selecionadas
    var aFoParcs = [];  // forma das parcelas selecionadas
    var aVlParcs = [];  // valor quitado das parcelas selecionadas
    var aVOParcs = [];  // valor original das parcelas selecionadas
    let key = 0;
    let iMarcadas = 0;  // qtd de parcelas marcadas
    for (let i = 0; i < aNodeListParcelas.length; i++) {
        if (aNodeListParcelas[i].checked)
        {
          // console.log(this.state.aForma);
          // console.log(this.state.aValor);
          if ((this.state.aForma[i] === 0) || (this.state.aForma[i] === "0") || (this.state.aValor[i] === 0))
          {
            alert("Informar valor e/ou forma de pagamento na parcela " + aNodeListParcelas[i].name.substring(8));
            isValid = false;
            break;
          }
          iMarcadas++;
          aIDParcs[key] = aNodeListParcelas[i].name.substring(8); // extrai o no. da parcela a partir da posição 8 até o fim
          aFoParcs[key] = this.state.aForma[i];
          aVlParcs[key] = this.state.aValor[i];
          aVOParcs[key] = aNodeListParcelas[i].alt * 1; // o valor original da parcela ficou no atributo alt
          key++;
        }
    }

    // precisa selecionar ao menos uma parcela
    // a propriedade name contem ID_PARC_xxxx, onde ID_PARC_ é fixo e xxxx é o id da parcela
    // aqui seleciona todos os inputs cujo nome comece com ID_PARC_
    if (isValid && (iMarcadas === 0))
    {
      alert("Selecione ao menos uma parcela.");
      isValid = false;
    }

    if ((! isValid) || (! window.confirm("Confirma quitação ?")))
    {
      event.preventDefault();
      event.stopPropagation();
      isValid = false;
    }

    // se os dados são válidos e confirmou a venda
    if (isValid)
    {
      /*
      // percorre todas as parcelas, independente de estarem ou não marcadas, mas só para as marcadas gera 3 arrays, um com o id das parcelas
      // marcadas, outro com as formas das parcelas marcadas e outro com os valores das parcelas marcadas
      var aNodeListParcelas = document.querySelectorAll('input[name^="ID_PARC_"]');
      var aIDParcs = [];  // id das parcelas selecionadas
      var aFoParcs = [];  // forma das parcelas selecionadas
      var aVlParcs = [];  // valor quitado das parcelas selecionadas
      var aVOParcs = [];  // valor original das parcelas selecionadas
      let key = 0;
      for (let i = 0; i < aNodeListParcelas.length; i++) {
          if (aNodeListParcelas[i].checked)
          {
            aIDParcs[key] = aNodeListParcelas[i].name.substring(8); // extrai o no. da parcela a partir da posição 8 até o fim
            aFoParcs[key] = this.state.aForma[i];
            aVlParcs[key] = this.state.aValor[i];
            aVOParcs[key] = aNodeListParcelas[i].alt * 1; // o valor original da parcela ficou no atributo alt
            key = key + 1;
          }
      }
      */
      // chama o programa php para salvar os dados
      const dadosPar = {
        nusu: localStorage.getItem("user"), // this.props.nUsuario,
        dqui: this.state.dQuitacao,
        aPar: JSON.stringify(aIDParcs),
        aFor: JSON.stringify(aFoParcs),
        aVal: JSON.stringify(aVlParcs),
        aVOr: JSON.stringify(aVOParcs),
        prog_trata: "./Financas/QuitarSalvar.php",
        func_trata: "QuitarVarios"
        }
        trataAxios(dadosPar);
    }
  };

  render() {
    return (
      <>
        <CabPage titulo="Quitar Parcelas" 
            subtitulo={this.state.aParcelas.length > 0 ? this.state.aParcelas.length + " parcelas em aberto" : ""}
            paginavoltar="/finsel/qui" classSubTit="sFonte6Branco"/>
        {this.state.aParcelas.msg === 'ERRO' &&
          <Alert variant="warning">Não há parcelas para listar !</Alert>
        }
        {this.state.aParcelas.length === 0 && 
          <AvisoParcelaQuitada />
        }
        {this.state.aParcelas.length > 0 && 
          <>
            <form validated={this.validated} onSubmit={this.handleSubmit} action="/finlis">
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th width="5%">Venda<br/>Parcela</th>
                    <th width="55%">Cliente, Parcela, Forma, $ Orig<br/>Vc: Vencimento; Vd: Venda</th>
                    <th width="40%">Forma<br/>$ Quitação</th>
                  </tr>
                </thead>
                <tbody>
                {this.state.aParcelas.length > 0 && 
                  this.state.aParcelas.map((oParcela, idx) => (
                  <tr key={idx}>
                    <td align="center">{oParcela.id_venda}<br/>{oParcela.id_vendas_parc}
                      <Form.Check type="checkbox" name={"ID_PARC_" + oParcela.id_vendas_parc} 
                        alt={oParcela.valor_parcela} id={"id_" + idx} 
                        onChange={this.handleCheckboxChange.bind(this)}/>
                      <ShowParcela idParcela={oParcela.id_vendas_parc} idVenda={oParcela.id_venda}/>
                    </td>              
                    <td>{oParcela.fantasia} {oParcela.id_cliente === CodConsum() ? " (" + oParcela.nome_consum + ") ": ""}
                        ({oParcela.id_cliente})<br/>
                        Parcela: {oParcela.num_parcela}&nbsp;&nbsp;&nbsp;&nbsp;
                        {oParcela.desc_forma}&nbsp;&nbsp;&nbsp;&nbsp;
                        {FormataValor(oParcela.valor_parcela)}<br/>
                        Vc: {oParcela.dt_vcto}<br/>
                        Vd: {oParcela.dt_venda}
                    </td>
                    <td>
                      <Form.Select required disabled 
                        name={"nForma_" + oParcela.id_vendas_parc} id={"idForma_" + oParcela.id_vendas_parc}
                        onChange={this.handleChangeForma(idx)} >
                        <SelectFormasPag id={oParcela.id_vendas_parc}/>
                      </Form.Select>
                      <Form.Control type="number" disabled step=".01" 
                          name={"nValor_" + oParcela.id_vendas_parc} id={"idValor_" + oParcela.id_vendas_parc}
//                          value={this.state.aValor[idx]}
                          onChange={this.handleChangeValor(idx)} onFocus={(e) => {e.target.select()}}/>
                    </td>
                  </tr>
                ))}
                </tbody>
              </Table>
              <i>Registros listados: {this.state.aParcelas.length}</i><br/><br/>
              <Row>
                <Col>
                  <FloatingLabel controlId="floatingDataQuitacao" label="Data Quitação">
                    <Form.Control required type="date" name="dataQuitacao" 
                      value={this.state.dQuitacao} 
                      onChange={this.handleChangeDatQui()}
                    />
                    <Form.Control.Feedback>OK!</Form.Control.Feedback>
                  </FloatingLabel>
                </Col>
                <Col>
                  <FloatingLabel controlId="floatingSumValSelOrig" label="&Sigma; Orig">
                    <Form.Control disabled type="number" value={FormataValor(this.state.nSumValSelOrig, "input")} />
                  </FloatingLabel>
                </Col>
                <Col>
                  <FloatingLabel controlId="floatingSumValSelQuit" label="&Sigma; Final">
                    <Form.Control disabled type="number" value={FormataValor(this.state.nSumValSelQuit, "input")} />
                  </FloatingLabel>
                </Col>
              </Row>
              <br/>
              <center>
                <Button as="button" type="submit" id="bt_qui" variant="success" size="lg">Quitar Selecionadas
                </Button>
              </center>
            </form>
            <br/>
          </>
        }
      </>
    );
  }
}

export default QuitarListarEProcessar;